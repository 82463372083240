.modal_container{
    width: 100vw;
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10000
  }

  /* Loading spinner container */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  top: -50px; /* Move it slightly up from the center */
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: red;
}

.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}