.topics__collections{
}
.topics__collection{
  border: 1px solid black;
  margin: 14px 0px;
  border-radius: 14px;
}
.topics__collection__title{
  padding: 14px;
  font-size: 20px;
  font-weight: 500;
}
.topics__categories{
  display: flex;
  padding: 14px 0px;
  overflow: auto;
}

.topics__categorie {
  margin: 0px 20px
}

.topics__categorie__name{
}

.topics__categorie__img{
  height: 140px;
}