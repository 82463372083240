.create_content_container{
  display: flex;
  width: 100%;
  /* height: 100%; */
}

.create_content_form{
  width: calc(100% - 360px);
}
.create_content_preview{
  width: 360px;
  height: 600px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
}

.create_content_form__buttons_containr{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create_content_form__poll_item{
  display: flex;
  justify-content: space-between;
  margin: 14px 0px;
}

.create_content_form__Heading{
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0px;
}

.create_content_form__buttons_width{
  width: 100px;
  background-color: aqua;
}

.create_content__border{
  border-bottom: 1px solid #dee2e6;
  margin: 25px 0px;
}

.content_preview__container{
  margin: 15px;
  overflow: hidden;
}
.content_preview__title{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.content_preview__description{
  margin-bottom: 10px;
  font-size: 14px;
}
.content_preview__lable{
  margin-bottom: 10px;
  text-align: right;
  font-size: 12px;
}
.content_preview__socials{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.content_preview__social{
}

.content_preview__socials_share_comment{
  display: flex;
}

.content_preview__social__action{
  display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 6px;
    margin-top: 10px;
}



.content_preview_poll__container{
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 15px;

  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.border{
  border: 1px solid #dee2e6;
}

.content_preview_poll__polls{
}
.content_preview_poll__poll{
  border: 1px solid #f5735a;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-bottom: 8px;
  border-radius: 20px;
  cursor: pointer;
}
.content_preview_poll__poll_details{
  display: flex;
  font-size: 12px;
}
.content_preview_poll__poll_detail{
  margin-right: 10px;
}

.content_preview_poll__poll_after{
      border: 1px solid #f5735a;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      margin-bottom: 8px;
      border-radius: 20px;
      cursor: pointer;
      position: relative;
}

.content_preview_poll__poll_after::after{
  content: "";
  background-color: rgba(168, 168, 168, 0.4);
  display: block;
  width: 30%;
  height: 100%;
  position: absolute;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  top: 0px;
  left: 0px;
}

.content_preview_poll__poll_after_selected{
  border: 1px solid #f5735a;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-bottom: 8px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
}
.content_preview_poll__poll_after_selected::after {
  content: "";
  background-color: rgba(245, 115, 90, 0.2);
  display: block;
  width: 60%;
  height: 100%;
  position: absolute;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  top: 0px;
  left: 0px;
}
