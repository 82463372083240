.meal-builder-plan-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.335);
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.meal-builder-plan-modal-container>div {
  border-radius: 10px;
  background-color: white;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.meal-builder-container {
  width: max-content;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
  padding: 64px 90px 0;
}

.meal-builder-container>div {
  display: flex;
  gap: 90px;
}

.meal-builder-head {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: #f5735a;
}

.meal-builder-info-container {
  display: flex;
  gap: 32px;
  margin: 42px 0 29px;
}

.meal-builder-profile-info {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 350px;
}

.meal-builder-container h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #373750;
  margin: 0;
}

.meal-builder-container h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #373750;
}

.meal-builder-profile-info>div {
  border: 1px solid #e2e4e5;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.meal-builder-profile-info>div label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #373750;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.meal-builder-profile-info>div input,
.meal-builder-profile-info>div select {
  padding: 8px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #373750;
  border-width: 0;
  border-bottom: 1px solid #e2e4e5;
  max-height: 44px;
  margin: 0;
  width: 100%;
  background-color: transparent;
}

.meal-builder-profile-info>div select option {
  border-width: 0;
  margin: 20px 2px;
  color: #373750 !important;
}

.meal-builder-profile-info>div select option[value=""] {
  color: #999999 !important;
  display: hidden;
}

.meal-builder-profile-info>div input:focus,
.meal-builder-profile-info>div select:focus {
  outline-width: 0;
}

.meal-builder-dietary-needs {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 42px 0 29px;
}

.meal-builder-dietary-needs>div {
  display: flex;
  gap: 32px;
}

.meal-builder-dietry-needs-p1 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  padding-right: 16px;
  border: 1px solid #e2e4e5;
  box-sizing: border-box;
  border-radius: 8px;
  width: 361px;
  height: max-content;
}

.meal-builder-dietry-needs-p2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.meal-builder-dietry-needs-p2>div {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  padding-right: 16px;
  border: 1px solid #e2e4e5;
  box-sizing: border-box;
  border-radius: 8px;
  width: 361px;
  height: max-content;
  gap: 21px;
}

.meal-builder-dietry-needs-p1>div,
.meal-builder-dietry-needs-p2>div>div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 28px;
}

.meal-builder-dietry-needs-p2>div:nth-child(2) {
  height: 100%;
  margin-top: 20px;
}

.meal-builder-dietry-needs-p2>div textarea {
  height: 100%;
  resize: none;
  border-width: 0;
  border-bottom: 1px solid #e2e4e5;
}

.meal-builder-dietry-needs-p2>div textarea:focus {
  outline-width: 0;
}

.meal-builder-diet-preference-val {
  display: flex;
  width: 100%;
  padding-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.meal-builder-dietry-needs-p2 button {
  width: max-content;
}

/* Custom Radio buttons */
.relative {
  position: relative;
  width: 24px;
  height: 24px;
}

.radio-item {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label.custom_label:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #d0deeb;
  background-color: #f1f5f8;
  cursor: pointer;
}

.radio-item.disabled {
  content: "";
  position: relative;
  z-index: 20;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.048);
}

.radio-item input[type="radio"]:checked+label.custom_label:after {
  border-radius: 11px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
  content: " ";
  display: block;
  background-color: #ffffff;
  border: 8px solid #f5735a;
}

/* Custom Checkbox buttons */
.checkbox-item input[type="checkbox"] {
  display: none;
}

.checkbox-item {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

.checkbox-item input[type="radio"] {
  display: none;
}

.checkbox-item label.custom_label svg {
  cursor: pointer;
  fill: #f1f5f8;
}

.checkbox-item label.custom_label:before {
  content: "";
  display: inline-block;
  position: relative;
}

.checkbox-item input[type="checkbox"]:checked+label.custom_label:after {
  position: absolute;
  top: 0px;
  left: 0px;
  content: " ";
  display: block;
  background-color: #ffffff;
}

.checkbox-item input[type="checkbox"]:checked+label.custom_label svg {
  fill: #f5735a;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.mealplan_builder__section{
  margin: 10px 0px;
}
.mealplan_builder__notes{
    margin: 10px 0px;
    min-height: 200px;
}