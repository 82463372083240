.modal_container{
    width: 100vw;
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10000
  }
input[type="file"] {
  display: none;
}